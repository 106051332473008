import { Component } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../services/loader.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent {
    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private readonly loaderService: LoaderService,
        private readonly route: ActivatedRoute,
    ) {
        this.route.queryParams.subscribe(params => {
            if (params.token) {
                this.authService.setToken(params.token);
            }

            if (this.authService.hasToken()) {
                this.router.navigateByUrl('/dashboard');
            } else {
                this.login();
            }
        });
    }

    public login(): void {
        this.loaderService.show();
        window.location.href = '/api/login/whmcs/redirect'
    }

    public getCurrentYear(): number {
        return new Date().getFullYear();
    }
}
